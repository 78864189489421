.project-filter {
  margin: 30px auto;
}
.dashboard-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-add-project {
  text-decoration: none;
  font-size: 0.7em;
  display: none;
}

.project-filter nav {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}
.project-filter p {
  font-size: 0.9em;
  margin-right: 10px;
}
.project-filter button {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-weight: bold;
  color: var(--text-color);
  cursor: pointer;
  border-right: 1px solid #e4e4e4;
  font-size: 0.9em;
}
.project-filter button:last-child {
  border: 0;
}
.project-filter button.active {
  color: var(--primary-color);
}

@media screen and (max-width: 1180px) {
  .project-filter {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 850px) {
  .project-filter {
    font-size: 0.6em;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-add-project {
    display: block;
  }
}

@media screen and (max-width: 460px) {
  .project-filter nav {
    flex-direction: column;
  }

  .project-filter nav > p {
    text-align: center;
    margin-bottom: 1em;
  }
  .project-filter nav {
    align-items: center;
    padding: 5px;
  }
}

@media screen and (max-width: 400px) {
  .project-filter nav {
    padding-inline: 1px;
  }
}
