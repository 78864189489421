.navbar {
  width: 100%;
  padding: 30px 0;
  box-sizing: border-box;
  margin-bottom: 40px;
}
.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}
.navbar .logo {
  font-weight: bold;
  color: var(--heading-color);
  letter-spacing: 1px;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.logo > a {
  display: flex;
  align-items: center;
}

.navbar .logo img {
  margin-right: 10px;
  filter: invert(20%);
  width: 36px;
  margin-top: -8px;
}
.navbar a {
  color: #333;
  text-decoration: none;
  margin-right: 20px;
}

@media screen and (max-width: 800px) {
  .navbar {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .navbar .btn {
    font-size: 0.7em;
  }
}
