.App {
  display: flex;
}
.App .container {
  flex-grow: 1;
  padding: 0 40px;
}

@media (max-width: 500px) {
  .App .container {
    padding: 0 20px;
  }
}

@media (max-width: 400px) {
  .App .container {
    padding: 0 15px;
  }
}
