.auth-form {
  max-width: 360px;
  margin: 60px auto;
  padding: 40px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.login-action {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 30px;
}

@media secreen and (max-width: 500px) {
  .auth-form {
    font-size: 0.8rem;
  }
}
