.landing h1 {
    font-size: 4rem;
    color: var(--primary-color);
    font-family: 'Caveat', cursive;
}

.landing__hero {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    gap: 3rem;
    
}

.landing__hero__text {
    margin-top: 5rem;
}

.landing__hero__text a {
    display: inline-block;
    margin-top: 2rem;
    margin-left: 2rem;
    color: white;
    font-family: 'Caveat', cursive;
    font-size: 1.8rem;
    text-decoration: none;
    position: relative;
}

.landing__hero__text a::before {
    content: "";
    position: absolute;
    left: 52%;
    bottom: 2%;
    width: 120%;
    height: 35px;
    transform: skew(-12deg) translateX(-50%) rotate(-1deg);
    background: var(--primary-color);
    z-index: -1;
    border-radius: 5px;
}


.landing__hero__image img {
    width: 400px;
}

@media screen and (max-width: 1100px) {
    .landing h1 {
      font-size: 3rem;
    }

    .landing__hero__text a {
        font-size: 1.3rem;
        margin-top: 1rem;
    }

    .landing__hero__text a::before {
        height: 25px;
    }
    
  }

  @media screen and (max-width: 950px) {
   
    .landing__hero__image img {
        margin-top: 2rem;
        width: 300px;
    }

    .landing h1 {
        font-size: 2.3rem;
      }

      .landing__hero__text a {
        margin-top: 1rem;
    }

    .landing__hero {
        gap: 2rem;
    }
  }

  @media screen and (max-width: 750px) {
    .landing__hero {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .landing__hero__text {
        margin-top: 1rem;
    }

    .landing__hero__image img {
        margin-top: 1rem;
    }

  }